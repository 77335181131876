export function reloadPage(scrollableElementId) {
  var reloadWithTurbolinks = (function () {
    var scrollPosition

    function reload () {
      var element = document.getElementById(scrollableElementId)
      scrollPosition = element.scrollTop
      Turbo.visit(window.location, { action: 'replace' })
    }

    document.addEventListener('turbo:load', function () {
      if (scrollPosition) {
        var element = document.getElementById(scrollableElementId)
        element.scrollTo(0, scrollPosition)
        scrollPosition = null
      }
    })

    return reload;
  })()

  var timeout = setTimeout(function() {
    reloadWithTurbolinks();
  }, 3000)

  document.addEventListener("turbo:before-visit", function() {
    clearTimeout(timeout)
    document.removeEventListener("turbo:before-visit")
  })
}
