// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import '@hotwired/turbo-rails';
import { Turbo } from "@hotwired/turbo-rails"
import "bootstrap"
import "../stylesheets/application"
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { reloadPage } from './src/reloadPage.js'

const images = require.context('../images', true)
const icons = require.context('../icons', true)

window.reloadPage = reloadPage;

document.addEventListener('turbo:before-cache', function() {
  const flash_message_element = document.querySelector('.alert')
  if (flash_message_element) {
    flash_message_element.remove()
  }
})

Rails.start()
ActiveStorage.start()
Turbo.session.drive = false
